@charset "utf-8";

@import "variables";


html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,input,textarea,button,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;font-weight:inherit;font-style:normal;vertical-align:baseline;}
body{-webkit-text-size-adjust:100%;line-height:1;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
ul,ol{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:none}
a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;}
ins{background-color:#ff9;color:#000;text-decoration:none}
mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}
del{text-decoration:line-through}
abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}
table{border-collapse:collapse;border-spacing:0}
input,select{vertical-align:middle}
a{color:inherit;text-decoration:none;outline:none;}
img{-ms-interpolation-mode: bicubic;}

::selection{background:#000;color:#fff;}
::-moz-selection{background:#000;color:#fff;}

*{
	box-sizing: border-box;
}

html{
	position: relative;
	overflow-x: hidden;
}
body{
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-size: $fontSize;
	font-family: $fontBase;
	line-height: $lineHeight;
	letter-spacing: .1em;
	color: $textColor;
	background-color: #fff;
	overflow-x: hidden;
}

h1{
	display: none;
}

img,
svg{
	width: 100%;
	height: auto;
}

.inner{
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 1100px;
	margin: auto;
	padding: 0 15px;
}
.inner.max800{max-width: 800px;}
.inner.max1000{max-width: 1000px;}
.inner.max1300{max-width: 1300px;}

.pc{display: none;}
.smt{display: block;}

.blur{
	filter: blur(4px);
}

a.permalink{
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#wrap{
	position: relative;
	z-index: 1;
}



footer {
	text-align: center;
	background: url(../img/common/footer/bg.jpg) no-repeat;
	background-size: cover;
	.inner{
		padding: 12% 15px;
	}
	#f-logo {
		line-height: 0;
		margin: 0 0 10px;
		a{
			img {
				width: 50%;
				max-width: 190px;
			}
		}
	}
	#copyright {
		font-size: 12px;
		padding: 10px 0;
		background-color: rgba(255,255,255,.6);
	}
}


#pagetop{
	display: none;
	cursor: pointer;
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 8;
	width: 60px;
	height: 60px;
	padding: 10px;
	background-color: #fff;
	span{
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		img{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 30px;
			height: auto;
			transform: translate(-50%, -50%);
		}
	}
}

#menu-btn {
	cursor: pointer;
	display: inline-block;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 9;
	width: 60px;
	height: 60px;
	background-color: #fff;
	> div{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 30px;
		height: 24px;
		transform: translate(-50%, -50%);
	}
	span {
		display: inline-block;
		position: absolute;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: #000;
		transition: all .3s;
	}
	span:nth-of-type(1) {
		top: 0;
	}
	span:nth-of-type(2) {
		top: 11px;
	}
	span:nth-of-type(3) {
		bottom: 0;
	}
}
#menu-btn.active{
	span:nth-of-type(1) {
		transform: translateY(11px) rotate(-315deg);
	}
	span:nth-of-type(2) {
		opacity: 0;
	}
	span:nth-of-type(3) {
		transform: translateY(-11px) rotate(315deg);
	}
}

nav#menu-global{
	text-align: center;
	overflow-x: hidden;
	overflow-y: scroll;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 8;
	width: 80%;
	max-width: 400px;
	height: 100%;
	background-color: #fff;
	transform: translateX(100%);
	transition: all .3s;
	#menu-logo{
		padding: 40px 20px 20px;
		a{
			img{
				width: 50%;
				max-width: 190px;
			}
		}
	}
	ul{
		li{
			a{
				position: relative;
				display: block;
				padding: 10px 0;
				span{
					position: relative;
					z-index: 1;
				}
			}
			a:before{
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 50%;
				width: 0;
				height: 100%;
				opacity: 0;
				background: linear-gradient(to right, #fff, rgba(41,102,162,.1) 50%, #fff 100%);
				transform: translateX(-50%);
				transition: all .3s;
			}
			a:hover:before{
				opacity: 1;
				width: 100%;
			}
		}
	}
	#menu-info{
		padding: 40px 0  50px;
		margin: 30px 30px 0;
		border-top: 1px solid rgba(0,0,0,.1);
	}
}
#menu-global.show{
	transform: translateX(0);
}

#menu-overlay{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 7;
	width: 100%;
	height: 100%;
	display: none;
	background-color: rgba(0,0,0,0.5);
}

#loading{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	width: 100%;
	height: 100%;
	background-color: #fff;
}
#loader{
	position: absolute;
	top: 50%;
	left: 50%;
	width: 38px;
	height: 38px;
	z-index: 9;
	transform: translate(-50%, -50%);
}


/* -------------------------------------- lower */
#lower{
	
	#main-view{
		position: relative;
		padding: 10px 10px 0;
		.main-bg{
			position: relative;
			span{
				display: block;
				overflow: hidden;
				position: relative;
				width: 100%;
				height: 0;
				padding-top: 50%;
				line-height: 0;
				opacity: 0;
				img{
					position: absolute;
					top: 50%;
					left: 50%;
					width: 100%;
					height: auto;
					transform: translate(-50%, -50%);
				}
			}
			span:before{
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				width: 100%;
				height: 100%;
				// background-color: rgba(41,102,162,.3);
				background-color: rgba(0,0,0,.3);
			}
		}
		.main-bg:before{
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 0;
			height: 0;
			border: 1px solid #000;
			opacity: 0;
			transform: translate(-50%, -50%);
			box-sizing: border-box;
		}
		h2{
			text-align: center;
			position: absolute;
			top: 60%;
			left: 50%;
			width: 100%;
			max-width: 600px;
			height: auto;
			opacity: 0;
			transform: translate(-50%, -50%);
			transition: all 1s .5s;
			svg{
				path{
					fill: #000;
					transition: all 1s 1s;
				}
			}
			small{
				display: block;
				color: #000;
				transition: all 1s 1s;
			}
		}
	}
	#main-view.on{
		.main-bg{
			span{
				animation-name: keyImageInner;
				animation-duration: 1s;
				animation-delay: 1s;
				animation-fill-mode: forwards;
				animation-iteration-count: 1;
			}
		}
		.main-bg:before{
			animation-name: keyImageStart,keyImageEnd;
			animation-duration: 1s,1s;
			animation-timing-function: cubic-bezier(.86,0,.07,1),cubic-bezier(.86,0,.07,1);
			animation-delay: 0s,1s;
			animation-direction: alternate;
			animation-fill-mode: forwards;
			animation-iteration-count: 1;
		}
		h2{
			top: 50%;
			opacity: 1;
			svg{
				path{
					fill: #fff;
				}
			}
			small{
				color: #fff;
				font-family: $fontGothic;
			}
		}
	}

	#breadcrumb {
		display: none;
	}

	.sec{
		padding: 10% 0 0;
		.inner{
			max-width: 1200px;
		}
		.sec-head{
			text-align: center;
			margin: 0 0 8%;
			h3{
				font-size: 16px;
				margin: 0 0 20px;
				span{
					display: inline-block;
				}
			}
			p{
				color: #333;
				font-family: $fontGothic;
			}
		}
		.sec-body{
			p{
				margin: 10px 0 0;
			}
		}
	}

	.pager{
		margin: 40px 5px 50px 0;
	}
	.pager.list{
		text-align: center;
		a,
		span{
			display: inline-block;
			line-height: 13px;
			padding: 14px 16px;
			margin: 5px;
			background-color: #fff;
			box-shadow: 4px 4px 0 rgba(0,0,0,0.2);
			transition: box-shadow .2s;
		}
		a:hover{
			// color: #fff;
			// background-color: $baseColor;
			box-shadow: 0 0 0 rgba(0,0,0,0.2);
		}
		span{
			color: #fff;
			background-color: #9d9d9d;
		}

		div{
			display: inline-block;
		}
	}
	.pager.single{
		table{
			width: 100%;
			tr{
				td{
					text-align: center;
					box-sizing: border-box;
					a{
						display: block;
						padding: 10px;
						background-color: #fff;
						box-shadow: 4px 4px 0 rgba(0,0,0,0.2);
						transition: box-shadow .2s;
						span{
							display: none;
						}
					}
					a:hover{
						box-shadow: 0 0 0 rgba(0,0,0,0.2);
					}
				}
				td.prev,
				td.next{
					width: 25%;
				}
				td.toidx{
					width: 50%;
					padding: 0 10px;
				}
			}
		}
	}

	footer{
		margin: 12% 0 0;
	}

}



@media screen and (min-width: 600px) {
	#pagetop{
		width: 80px;
		height: 80px;
	}
	#menu-btn {
		width: 80px;
		height: 80px;
	}

	footer {
		.inner{
			padding: 10% 15px;
		}
	}

/* -------------------------------------- lower */
	#lower{
		#main-view{
			padding: 15px 15px 0;
		}
		.sec{
			padding: 8% 0 0;
			.sec-head{
				margin: 0 0 6%;
				h3{
					font-size: 18px;
				}
			}
		}

		.pager.single{
			table{
				tr{
					td{
						a{
							position: relative;
							padding: 15px;
							span{
								display: inline;
							}
						}
					}
					td.next,
					td.prev{
						a:before{
							content: '';
							display: block;
							border: 4px solid transparent;
							position: absolute;
							top: 50%;
						}
					}
					td.next{
						a:before{
							right: 10px;
							border-left: 5px solid #9d9d9d;
							transform: translateY(-50%);
						}
					}
					td.prev{
						a:before{
							left: 10px;
							border-right: 5px solid #9d9d9d;
							transform: translateY(-50%);
						}
					}
				}
			}
		}

		footer {
		    margin: 10% 0 0;
		}
	}


}


@media screen and (min-width: 800px) {
	.pc{display: block;}
	.smt{display: none;}

	footer {
		.inner{
			padding: 80px 15px;
		}
	}
	

/* -------------------------------------- lower */
	#lower{
		
		#main-view{
			.main-bg{
				span{
					padding-top: 400px;
				}
			}
		}

		#breadcrumb {
			display: block;
			margin: 0 auto;
			font-family: $fontGothic;
			// box-sizing: content-box;
			.inner{
				// padding: 15px;
				padding: 15px 15px 0;
				ol {
					overflow: hidden;
					li {
						float: left;
						font-size: 13px;
						margin: 0px 10px 0px 0px;
						a {
							background: transparent url(../img/common/arw_bc.png) no-repeat scroll right 5px;
							padding: 0px 14px 0px 0px;
						}
						a:hover{
							text-decoration: underline;
						}
					}
					li.categorize {
						background: transparent url(../img/common/arw_bc.png) no-repeat scroll right 8px;
						padding: 0px 14px 0px 0px;
					}
				}
			}
		}

		.sec{
			padding: 6% 0 0;
			.sec-head{
				text-align: left;
				margin: 0 0 4%;
				h3{
					font-size: 20px;
				}
			}
		}

		.pager{
			margin: 60px 5px 0 0;
			padding: 0 0 5px;
		}

		footer {
			margin: 80px 0 0;
		}
	}

}


@media screen and (min-width: 1000px) {
	
	footer {
		.inner{
			padding: 100px 15px;
		}
	}

/* -------------------------------------- lower */
	#lower{
		.sec{
			.sec-head{
				margin: 0 0 40px;
				h3{
					font-size: 22px;
				}
			}
		}

		.pager.single{
			table{
				tr{
					td{
						a{
							position: relative;
							padding: 20px;
						}
					}
				}
			}
		}

		footer {
			margin: 100px 0 0;
		}
	}

}


@media screen and (min-width: 1200px) {

/* -------------------------------------- lower */
	#lower{
		.sec{
			.sec{
				padding: 70px 0 0;
				h3{
					font-size: 24px;
				}
			}
		}
	}

}


.key-basic{
	position: relative;
	top: 100px;
	opacity: 0;
	transition: top 1.5s ease,
				opacity 1.5s ease;
}
.key-basic.on{
	top: 0;
	opacity: 1;
}

/* key-mask */
.key-mask{
	// display: none;
	line-height: 0;
	svg{
		width: 80%;
		max-width: 600px;
	}
}

.key-img{
	position: relative;
	span{
		display: block;
		line-height: 0;
		img{
			opacity: 0;
		}
	}
}
.key-img:before{
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 0;
	height: 0;
	border: 1px solid #000;
	opacity: 0;
	transform: translate(-50%, -50%);
	box-sizing: border-box;
}
.key-img.on{
	span{
		img{
			animation-name: keyImageInner;
			animation-duration: 1s;
			animation-delay: 1s;
			animation-fill-mode: forwards;
			animation-iteration-count: 1;
		}
	}
}
.key-img.on:before{
	animation-name: keyImageStart,keyImageEnd;
	animation-duration: 1s,1s;
	animation-timing-function: cubic-bezier(.86,0,.07,1),cubic-bezier(.86,0,.07,1);
	animation-delay: 0s,1s;
	animation-direction: alternate;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
}

@keyframes keyImageStart {
	0%{width: 0; height: 0; opacity: 0;}
	100%{width: 100%; height: 100%; opacity: 1;}
}
@keyframes keyImageEnd {
	0%{opacity: 1;}
	100%{opacity: 0;}
}
@keyframes keyImageInner {
	0%{opacity: 0;}
	100%{opacity: 1;}
}
