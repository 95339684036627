
$baseColor: #2966A2; // blue rgba(41,102,162)

$textColor: #000;
$fontSize: 14px;
$lineHeight: 2;


/* font-family */
$fontBase: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
$fontGothic: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", 'Open Sans', YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
$fontJa: 'Noto Sans Japanese', serif;


/* easing */
@mixin easeOutExpo_1s {
	transition: all 1s cubic-bezier(0.190, 1.000, 0.220, 1.000);
	transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);
}
@mixin easeOutExpo_2s {
	transition: all 2s cubic-bezier(0.190, 1.000, 0.220, 1.000);
	transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

@mixin easeInExpo {
	transition: all .5s cubic-bezier(0.950, 0.050, 0.795, 0.035);
	transition-timing-function: cubic-bezier(0.950, 0.050, 0.795, 0.035);
}

@mixin easeOutBack {
	transition: all .5s cubic-bezier(0.175, 0.885, 0.305, 1.650);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.305, 1.650);
}

@mixin easeInOutExpo {
	transition: all .5s cubic-bezier(1.000, 0.000, 0.000, 1.000);
	transition-timing-function: cubic-bezier(1.000, 0.000, 0.000, 1.000);
}
