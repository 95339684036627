@charset "UTF-8";
/* font-family */
/* easing */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, input, textarea, button, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font-weight: inherit;
  font-style: normal;
  vertical-align: baseline;
}

body {
  -webkit-text-size-adjust: 100%;
  line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ul, ol {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, select {
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

img {
  -ms-interpolation-mode: bicubic;
}

::selection {
  background: #000;
  color: #fff;
}

::-moz-selection {
  background: #000;
  color: #fff;
}

* {
  box-sizing: border-box;
}

html {
  position: relative;
  overflow-x: hidden;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  line-height: 2;
  letter-spacing: .1em;
  color: #000;
  background-color: #fff;
  overflow-x: hidden;
}

h1 {
  display: none;
}

img,
svg {
  width: 100%;
  height: auto;
}

.inner {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1100px;
  margin: auto;
  padding: 0 15px;
}

.inner.max800 {
  max-width: 800px;
}

.inner.max1000 {
  max-width: 1000px;
}

.inner.max1300 {
  max-width: 1300px;
}

.pc {
  display: none;
}

.smt {
  display: block;
}

.blur {
  filter: blur(4px);
}

a.permalink {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#wrap {
  position: relative;
  z-index: 1;
}

footer {
  text-align: center;
  background: url(../img/common/footer/bg.jpg) no-repeat;
  background-size: cover;
}

footer .inner {
  padding: 12% 15px;
}

footer #f-logo {
  line-height: 0;
  margin: 0 0 10px;
}

footer #f-logo a img {
  width: 50%;
  max-width: 190px;
}

footer #copyright {
  font-size: 12px;
  padding: 10px 0;
  background-color: rgba(255, 255, 255, 0.6);
}

#pagetop {
  display: none;
  cursor: pointer;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 8;
  width: 60px;
  height: 60px;
  padding: 10px;
  background-color: #fff;
}

#pagetop span {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

#pagetop span img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: auto;
  transform: translate(-50%, -50%);
}

#menu-btn {
  cursor: pointer;
  display: inline-block;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: 60px;
  height: 60px;
  background-color: #fff;
}

#menu-btn > div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 24px;
  transform: translate(-50%, -50%);
}

#menu-btn span {
  display: inline-block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
  transition: all .3s;
}

#menu-btn span:nth-of-type(1) {
  top: 0;
}

#menu-btn span:nth-of-type(2) {
  top: 11px;
}

#menu-btn span:nth-of-type(3) {
  bottom: 0;
}

#menu-btn.active span:nth-of-type(1) {
  transform: translateY(11px) rotate(-315deg);
}

#menu-btn.active span:nth-of-type(2) {
  opacity: 0;
}

#menu-btn.active span:nth-of-type(3) {
  transform: translateY(-11px) rotate(315deg);
}

nav#menu-global {
  text-align: center;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 8;
  width: 80%;
  max-width: 400px;
  height: 100%;
  background-color: #fff;
  transform: translateX(100%);
  transition: all .3s;
}

nav#menu-global #menu-logo {
  padding: 40px 20px 20px;
}

nav#menu-global #menu-logo a img {
  width: 50%;
  max-width: 190px;
}

nav#menu-global ul li a {
  position: relative;
  display: block;
  padding: 10px 0;
}

nav#menu-global ul li a span {
  position: relative;
  z-index: 1;
}

nav#menu-global ul li a:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%;
  opacity: 0;
  background: linear-gradient(to right, #fff, rgba(41, 102, 162, 0.1) 50%, #fff 100%);
  transform: translateX(-50%);
  transition: all .3s;
}

nav#menu-global ul li a:hover:before {
  opacity: 1;
  width: 100%;
}

nav#menu-global #menu-info {
  padding: 40px 0  50px;
  margin: 30px 30px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#menu-global.show {
  transform: translateX(0);
}

#menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

#loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 38px;
  height: 38px;
  z-index: 9;
  transform: translate(-50%, -50%);
}

/* -------------------------------------- lower */
#lower #main-view {
  position: relative;
  padding: 10px 10px 0;
}

#lower #main-view .main-bg {
  position: relative;
}

#lower #main-view .main-bg span {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 50%;
  line-height: 0;
  opacity: 0;
}

#lower #main-view .main-bg span img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}

#lower #main-view .main-bg span:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

#lower #main-view .main-bg:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border: 1px solid #000;
  opacity: 0;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}

#lower #main-view h2 {
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  width: 100%;
  max-width: 600px;
  height: auto;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: all 1s .5s;
}

#lower #main-view h2 svg path {
  fill: #000;
  transition: all 1s 1s;
}

#lower #main-view h2 small {
  display: block;
  color: #000;
  transition: all 1s 1s;
}

#lower #main-view.on .main-bg span {
  animation-name: keyImageInner;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

#lower #main-view.on .main-bg:before {
  animation-name: keyImageStart,keyImageEnd;
  animation-duration: 1s,1s;
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1), cubic-bezier(0.86, 0, 0.07, 1);
  animation-delay: 0s,1s;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

#lower #main-view.on h2 {
  top: 50%;
  opacity: 1;
}

#lower #main-view.on h2 svg path {
  fill: #fff;
}

#lower #main-view.on h2 small {
  color: #fff;
  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
}

#lower #breadcrumb {
  display: none;
}

#lower .sec {
  padding: 10% 0 0;
}

#lower .sec .inner {
  max-width: 1200px;
}

#lower .sec .sec-head {
  text-align: center;
  margin: 0 0 8%;
}

#lower .sec .sec-head h3 {
  font-size: 16px;
  margin: 0 0 20px;
}

#lower .sec .sec-head h3 span {
  display: inline-block;
}

#lower .sec .sec-head p {
  color: #333;
  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
}

#lower .sec .sec-body p {
  margin: 10px 0 0;
}

#lower .pager {
  margin: 40px 5px 50px 0;
}

#lower .pager.list {
  text-align: center;
}

#lower .pager.list a,
#lower .pager.list span {
  display: inline-block;
  line-height: 13px;
  padding: 14px 16px;
  margin: 5px;
  background-color: #fff;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow .2s;
}

#lower .pager.list a:hover {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

#lower .pager.list span {
  color: #fff;
  background-color: #9d9d9d;
}

#lower .pager.list div {
  display: inline-block;
}

#lower .pager.single table {
  width: 100%;
}

#lower .pager.single table tr td {
  text-align: center;
  box-sizing: border-box;
}

#lower .pager.single table tr td a {
  display: block;
  padding: 10px;
  background-color: #fff;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow .2s;
}

#lower .pager.single table tr td a span {
  display: none;
}

#lower .pager.single table tr td a:hover {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

#lower .pager.single table tr td.prev,
#lower .pager.single table tr td.next {
  width: 25%;
}

#lower .pager.single table tr td.toidx {
  width: 50%;
  padding: 0 10px;
}

#lower footer {
  margin: 12% 0 0;
}

@media screen and (min-width: 600px) {
  #pagetop {
    width: 80px;
    height: 80px;
  }
  #menu-btn {
    width: 80px;
    height: 80px;
  }
  footer .inner {
    padding: 10% 15px;
  }
  /* -------------------------------------- lower */
  #lower #main-view {
    padding: 15px 15px 0;
  }
  #lower .sec {
    padding: 8% 0 0;
  }
  #lower .sec .sec-head {
    margin: 0 0 6%;
  }
  #lower .sec .sec-head h3 {
    font-size: 18px;
  }
  #lower .pager.single table tr td a {
    position: relative;
    padding: 15px;
  }
  #lower .pager.single table tr td a span {
    display: inline;
  }
  #lower .pager.single table tr td.next a:before,
  #lower .pager.single table tr td.prev a:before {
    content: '';
    display: block;
    border: 4px solid transparent;
    position: absolute;
    top: 50%;
  }
  #lower .pager.single table tr td.next a:before {
    right: 10px;
    border-left: 5px solid #9d9d9d;
    transform: translateY(-50%);
  }
  #lower .pager.single table tr td.prev a:before {
    left: 10px;
    border-right: 5px solid #9d9d9d;
    transform: translateY(-50%);
  }
  #lower footer {
    margin: 10% 0 0;
  }
}

@media screen and (min-width: 800px) {
  .pc {
    display: block;
  }
  .smt {
    display: none;
  }
  footer .inner {
    padding: 80px 15px;
  }
  /* -------------------------------------- lower */
  #lower #main-view .main-bg span {
    padding-top: 400px;
  }
  #lower #breadcrumb {
    display: block;
    margin: 0 auto;
    font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  }
  #lower #breadcrumb .inner {
    padding: 15px 15px 0;
  }
  #lower #breadcrumb .inner ol {
    overflow: hidden;
  }
  #lower #breadcrumb .inner ol li {
    float: left;
    font-size: 13px;
    margin: 0px 10px 0px 0px;
  }
  #lower #breadcrumb .inner ol li a {
    background: transparent url(../img/common/arw_bc.png) no-repeat scroll right 5px;
    padding: 0px 14px 0px 0px;
  }
  #lower #breadcrumb .inner ol li a:hover {
    text-decoration: underline;
  }
  #lower #breadcrumb .inner ol li.categorize {
    background: transparent url(../img/common/arw_bc.png) no-repeat scroll right 8px;
    padding: 0px 14px 0px 0px;
  }
  #lower .sec {
    padding: 6% 0 0;
  }
  #lower .sec .sec-head {
    text-align: left;
    margin: 0 0 4%;
  }
  #lower .sec .sec-head h3 {
    font-size: 20px;
  }
  #lower .pager {
    margin: 60px 5px 0 0;
    padding: 0 0 5px;
  }
  #lower footer {
    margin: 80px 0 0;
  }
}

@media screen and (min-width: 1000px) {
  footer .inner {
    padding: 100px 15px;
  }
  /* -------------------------------------- lower */
  #lower .sec .sec-head {
    margin: 0 0 40px;
  }
  #lower .sec .sec-head h3 {
    font-size: 22px;
  }
  #lower .pager.single table tr td a {
    position: relative;
    padding: 20px;
  }
  #lower footer {
    margin: 100px 0 0;
  }
}

@media screen and (min-width: 1200px) {
  /* -------------------------------------- lower */
  #lower .sec .sec {
    padding: 70px 0 0;
  }
  #lower .sec .sec h3 {
    font-size: 24px;
  }
}

.key-basic {
  position: relative;
  top: 100px;
  opacity: 0;
  transition: top 1.5s ease, opacity 1.5s ease;
}

.key-basic.on {
  top: 0;
  opacity: 1;
}

/* key-mask */
.key-mask {
  line-height: 0;
}

.key-mask svg {
  width: 80%;
  max-width: 600px;
}

.key-img {
  position: relative;
}

.key-img span {
  display: block;
  line-height: 0;
}

.key-img span img {
  opacity: 0;
}

.key-img:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border: 1px solid #000;
  opacity: 0;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}

.key-img.on span img {
  animation-name: keyImageInner;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.key-img.on:before {
  animation-name: keyImageStart,keyImageEnd;
  animation-duration: 1s,1s;
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1), cubic-bezier(0.86, 0, 0.07, 1);
  animation-delay: 0s,1s;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

@keyframes keyImageStart {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}

@keyframes keyImageEnd {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes keyImageInner {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
